import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDateInput from "@/components/input/pbDateInput/pbDateInput";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import PbInput from "@/components/input/pbInput/pbInput";
import PbRichTextEditor from "@/components/input/pbRichTextEditor/pbRichTextEditor";
import PbTimeInput from "@/components/input/pbTimeInput/pbTimeInput";
import PbRichTextView from "@/components/pbRichTextView/pbRichTextView";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { useAppSelector } from "@/store/store";
import {
  CmsPropertyAttribute,
  CmsPropertyAttributeGroup,
  CmsPropertyRelationValue,
  CmsPropertyValues,
} from "@/types/strapi";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { convertTimeStringToDate } from "@/utils/util";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SelectChangeEvent } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useRouter } from "next/router";
import CmsPropertiesTimeAndScheduling from "./cmsPropertiesTimeAndScheduling";
import CmsPropertyDataProvider from "./cmsPropertyDataProvider";
import CmsPropertyMedia from "./cmsPropertyMedia";
import CmsPropertyRelation from "./cmsPropertyRelation";

export interface CmsPropertiesProps {
  propertyAttributeGroups: Array<CmsPropertyAttributeGroup>;
  propertyValues: CmsPropertyValues;
  onChange: (
    attributeGroupName: string,
    attributeName: string,
    value: any
  ) => void;
}

export default function CmsProperties(props: CmsPropertiesProps) {
  const tCms = useCmsTranslation();
  const router = useRouter();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const { richTextClassName, richtextEditorSettings } = useCesStrRichText();

  const renderGenericPropertyInputField = (
    attributeGroup: CmsPropertyAttributeGroup,
    attribute: CmsPropertyAttribute
  ) => {
    const currentValue =
      props.propertyValues[attributeGroup.name][attribute.name];
    const isNonLocalizedDisabled =
      editView &&
      attribute.nonLocalized &&
      !isLocaleDefaultLocale(router.locale!);
    switch (attribute.type) {
      case "string":
        return (
          <PbInput
            type="text"
            defaultValue={currentValue}
            fullWidth
            disabled={isNonLocalizedDisabled}
            onChange={(e: any) => {
              props.onChange(
                attributeGroup.name,
                attribute.name,
                e.target.value
              );
            }}
          />
        );
      case "richtext":
        return (
          <>
            {isNonLocalizedDisabled ? (
              <PbRichTextView className="" htmlString={currentValue} />
            ) : (
              <PbRichTextEditor
                className={richTextClassName}
                settings={richtextEditorSettings}
                onChange={(value: string) =>
                  props.onChange(attributeGroup.name, attribute.name, value)
                }
                content={currentValue}
              />
            )}
          </>
        );
      case "boolean":
        return (
          <PbCheckBox
            withoutColon
            value={currentValue}
            isSlider={true}
            isDisabled={isNonLocalizedDisabled}
            onChange={() => {
              props.onChange(
                attributeGroup.name,
                attribute.name,
                !currentValue
              );
            }}
            fullHeight
          />
        );
      case "date":
        return (
          <PbDateInput
            fullWidth
            value={currentValue}
            disabled={isNonLocalizedDisabled}
            onChange={(value) =>
              props.onChange(attributeGroup.name, attribute.name, value)
            }
          />
        );
      case "time":
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {attribute.optional && (
              <PbCheckBox
                withoutColon
                value={!!currentValue}
                isSlider={true}
                isDisabled={isNonLocalizedDisabled}
                onChange={() => {
                  if (currentValue) {
                    props.onChange(attributeGroup.name, attribute.name, null);
                  } else {
                    props.onChange(
                      attributeGroup.name,
                      attribute.name,
                      "00:00"
                    );
                  }
                }}
                fullHeight
              />
            )}

            <PbTimeInput
              fullWidth
              disabled={
                (attribute.optional && !currentValue) || isNonLocalizedDisabled
              }
              disabledPlaceholder={
                attribute.optional && currentValue === null
                  ? tCms("noTime")
                  : undefined
              }
              value={convertTimeStringToDate(
                currentValue ?? "00:00"
              ).toISOString()}
              onChange={(value) => {
                props.onChange(attributeGroup.name, attribute.name, value);
              }}
            />
          </div>
        );
      case "enum":
        return (
          <PbDropdown
            disabled={isNonLocalizedDisabled}
            dropdownList={attribute.options!}
            onChange={(event: SelectChangeEvent) => {
              props.onChange(
                attributeGroup.name,
                attribute.name,
                event.target.value
              );
            }}
            defaultValue={currentValue}
            itemNameKey={"name"}
            itemValueKey={"value"}
          />
        );
      case "media":
        return (
          <CmsPropertyMedia
            attributeGroup={attributeGroup}
            attribute={attribute}
            currentValue={currentValue as CmsPropertyRelationValue}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "dataProvider":
        return (
          <CmsPropertyDataProvider
            attributeGroup={attributeGroup}
            attribute={attribute}
            currentValue={currentValue}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "relation":
        return (
          <CmsPropertyRelation
            attributeGroup={attributeGroup}
            attribute={attribute}
            currentValue={currentValue as CmsPropertyRelationValue}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
    }
  };

  return (
    <>
      {props.propertyAttributeGroups?.map((attributeGroup, index) => {
        switch (attributeGroup.name) {
          case "timeAndSchedulingDetails":
            return (
              <CmsPropertiesTimeAndScheduling
                key={index}
                propertyAttributeGroup={attributeGroup}
                propertyValues={props.propertyValues}
                onChange={(attributeGroupName, attributeName, value) => {
                  props.onChange(attributeGroupName, attributeName, value);
                }}
              />
            );
          default:
            return (
              <Accordion defaultExpanded key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                  sx={{
                    fontSize: 18,
                    fontWeight: "600",
                    borderBottom: "1px solid #c2c2c2",
                    borderTop: "1px solid #f2f2f2",
                  }}
                >
                  {tCms(
                    `propertyGroup-${attributeGroup.name}`,
                    undefined,
                    attributeGroup.name
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="cms-modal-input-wrapper-container">
                    {attributeGroup.attributes?.map(
                      (attribute, attributeIndex) => {
                        return (
                          <ModalInputWrapper
                            fullWidth={attribute.type === "richtext"}
                            key={attributeIndex}
                            label={tCms(
                              `property-${attribute.name}`,
                              undefined,
                              attribute.name
                            )}
                            description={tCms(
                              `property-description-${attribute.name}`,
                              undefined,
                              attribute.name
                            )}
                            nonLocalized={attribute.nonLocalized}
                          >
                            {renderGenericPropertyInputField(
                              attributeGroup,
                              attribute
                            )}
                          </ModalInputWrapper>
                        );
                      }
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
        }
      })}
    </>
  );
}
