import CmsMaxLengthIndicator from "@/components/cms/cmsMaxLengthIndicator/cmsMaxLengthIndicator";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import { forwardRef, useRef, useState } from "react";
import PbInputLabel from "../pbInputLabel/pbInputLabel";

export interface PbInputControlledProps extends OutlinedInputProps {
  /**
   * The label for the input.
   */
  label: string;

  /**
   * The maximum length allowed for the input.
   */
  maxLength?: number;

  /**
   * Indicates whether the input is mandatory.
   */
  mandatory?: boolean;

  /**
   * Indicates whether to hide the length indicator.
   */
  hideLengthIndicator?: boolean;

  /**
   * Specifies the distance from the right for displaying the length indicator.
   */
  showMaxLengthIndicatorRight?: string;

  /**
   * The error text to display.
   */
  errorText?: string;

  /**
   * The callback function called when the value of the input changes.
   * @param event - The change event.
   */
  onChange?: (event: any) => void;

  /**
   * The callback function called when the input loses focus.
   */
  onBlur?: (event: any) => void;
}

/**
 * PbInputControlled component represents an input field with controlled state.
 * @param {PbInputControlledProps} props - The component properties.
 * @param {React.RefObject<HTMLInputElement>} ref - The ref object for the input element.
 * @returns {JSX.Element} A JSX element representing the controlled input.
 */
const PbInputControlled = forwardRef<HTMLInputElement, PbInputControlledProps>(
  (props: PbInputControlledProps, ref: any) => {
    const { t: tPublic } = useTranslation("public");

    const isControlled = typeof props.value !== "undefined";
    const hasDefaultValue = typeof props.defaultValue !== "undefined";

    const [internalValue, setInternalValue] = useState(
      hasDefaultValue ? props.defaultValue : ""
    );

    const value = isControlled ? props.value : internalValue;

    const onChange = (event: any) => {
      props.onChange && props.onChange(event);
      if (!isControlled) {
        setInternalValue(event.target.value);
      }
    };

    const maxLength = props.maxLength || 250;
    const inputWithMaxLength = useRef<HTMLInputElement | null>(null);

    return (
      <>
        {props.label && (
          <>
            <PbInputLabel label={props.label} />
          </>
        )}
        <div className="with-max-length-indicator">
          <OutlinedInput
            startAdornment={props.startAdornment}
            multiline={props.multiline ? props.multiline : false}
            fullWidth={props.fullWidth ? props.fullWidth : false}
            sx={props.sx ? props.sx : null}
            className={
              props.error
                ? "cms-input-error cms-collection-type-text"
                : "cms-collection-type-text"
            }
            type={props.type ? props.type : "text"}
            name={props.name ? props.name : ""}
            value={value}
            onChange={onChange}
            placeholder={props.placeholder ? props.placeholder : ""}
            autoFocus={props.autoFocus ? props.autoFocus : false}
            onBlur={props.onBlur}
            inputProps={{ maxLength: `${maxLength}` }}
            inputRef={ref ? ref : inputWithMaxLength}
            error={props.error}
            minRows={props.minRows ? props.minRows : ""}
            endAdornment={
              props.endAdornment ? (
                <>
                  {props.endAdornment}
                  {props.error ? (
                    <InputAdornment position="end">
                      <div className="input-error-icon">
                        <PbIcon
                          name="triangle-exclamation-light"
                          className="svg-fill-red"
                          width={20}
                          height={20}
                        />
                      </div>
                    </InputAdornment>
                  ) : null}
                </>
              ) : (
                <>
                  {props.error ? (
                    <InputAdornment position="end">
                      <div className="input-error-icon">
                        <PbIcon
                          name="triangle-exclamation-light"
                          className="svg-fill-red"
                          width={20}
                          height={20}
                        />
                      </div>
                    </InputAdornment>
                  ) : null}
                </>
              )
            }
            disabled={props.disabled}
          />
          {!props.hideLengthIndicator ? (
            <CmsMaxLengthIndicator
              // default is bottom right below the input
              right={props.showMaxLengthIndicatorRight ? "-51px" : "0px"}
              maxLength={maxLength}
              length={
                ref
                  ? ref.current?.value.length
                  : inputWithMaxLength.current?.value.length
              }
            />
          ) : null}
        </div>
        {props.error && (
          <div className="cms-input-error-msg">
            {props.errorText ? props.errorText : tPublic("inputIncorrect")}
          </div>
        )}
        {/*<CmsInputStyle />*/}
      </>
    );
  }
);

PbInputControlled.displayName = "PbInputControlled";

export default PbInputControlled;
