import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDateTime from "@/components/input/pbDateTime/pbDateTime";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { useAppSelector } from "@/store/store";
import {
  CmsPropertyAttribute,
  CmsPropertyAttributeEnum,
  CmsPropertyAttributeGroup,
  CmsPropertyValues,
} from "@/types/strapi";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { convertTimeStringToDate } from "@/utils/util";
import { Accordion, AccordionSummary, SelectChangeEvent } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useRouter } from "next/router";

export interface CmsPropertiesTimeAndSchedulingProps {
  propertyAttributeGroup: CmsPropertyAttributeGroup;
  propertyValues: CmsPropertyValues;
  onChange: (
    attributeGroupName: string,
    attributeName: string,
    value: any
  ) => void;
}

export default function CmsPropertiesTimeAndScheduling(
  props: CmsPropertiesTimeAndSchedulingProps
) {
  const router = useRouter();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const tCms = useCmsTranslation();

  const getPropertyAttribute = (attributeName: string) => {
    const attributes = props.propertyAttributeGroup.attributes;
    return attributes.find((attribute) => attribute.name === attributeName);
  };

  const getPropertyValue = (attributeName: string) => {
    const attributeGroupName = props.propertyAttributeGroup.name;
    return props.propertyValues?.[attributeGroupName]?.[attributeName];
  };

  const parseDateAndTimeToDateTime = (
    date: string | undefined,
    time: string | undefined
  ) => {
    const baseDate = date ? new Date(date) : new Date();
    return time ? convertTimeStringToDate(time, baseDate) : baseDate;
  };

  const getEndStartDate = (
    endDate: string | undefined,
    endTime: string | undefined
  ) => {
    if (!endDate && !endTime) {
      return undefined;
    }
    return parseDateAndTimeToDateTime(endDate, endTime);
  };

  const onDateTimeInputChange = (
    dateAttributeName: string,
    timeAttributeName: string,
    value: any,
    type: "date" | "time"
  ) => {
    if (type === "date") {
      props.onChange(
        props.propertyAttributeGroup.name,
        dateAttributeName,
        value?.toString() ?? null
      );
    }
    if (type === "time") {
      props.onChange(
        props.propertyAttributeGroup.name,
        timeAttributeName,
        value?.toString() ?? null
      );
    }
  };

  const isNonLocalizedDisabled = (
    attribute: CmsPropertyAttribute | undefined
  ) => {
    if (!attribute) {
      return false;
    }
    return (
      editView &&
      attribute.nonLocalized &&
      !isLocaleDefaultLocale(router.locale!)
    );
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls={`panel-timeAndScheduling-content`}
        id={`panel-timeAndScheduling-header`}
        sx={{
          fontSize: 18,
          fontWeight: "600",
          borderBottom: "1px solid #c2c2c2",
          borderTop: "1px solid #f2f2f2",
        }}
      >
        {tCms(
          `propertyGroup-${props.propertyAttributeGroup.name}`,
          undefined,
          props.propertyAttributeGroup.name
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div className="cms-modal-input-wrapper-container">
          <ModalInputWrapper
            label={tCms("event-startTime")}
            description={tCms("event-startDateTimeDescription")}
            nonLocalized={getPropertyAttribute("startDate")?.nonLocalized}
          >
            <PbDateTime
              fullWidth
              disabled={isNonLocalizedDisabled(
                getPropertyAttribute("startDate")
              )}
              minDate={undefined}
              value={parseDateAndTimeToDateTime(
                getPropertyValue("startDate"),
                getPropertyValue("startTime")
              ).toISOString()}
              hideTime={getPropertyValue("allDay")}
              onChange={(value, type) => {
                onDateTimeInputChange("startDate", "startTime", value, type);
              }}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("event-endTime")}
            description={tCms("event-endDateTimeDescription")}
            nonLocalized={getPropertyAttribute("endDate")?.nonLocalized}
          >
            <PbDateTime
              fullWidth
              disabled={isNonLocalizedDisabled(getPropertyAttribute("endDate"))}
              minDate={getEndStartDate(
                getPropertyValue("startDate"),
                getPropertyValue("startTime")
              )}
              value={parseDateAndTimeToDateTime(
                getPropertyValue("endDate"),
                getPropertyValue("endTime")
              ).toISOString()}
              hideTime={getPropertyValue("allDay")}
              onChange={(value, type) => {
                onDateTimeInputChange("endDate", "endTime", value, type);
              }}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("event-allDay")}
            description={tCms("event-fullDayDescription")}
            nonLocalized={getPropertyAttribute("allDay")?.nonLocalized}
          >
            <PbCheckBox
              isDisabled={isNonLocalizedDisabled(
                getPropertyAttribute("allDay")
              )}
              withoutColon
              value={getPropertyValue("allDay")}
              isSlider={true}
              onChange={() => {
                props.onChange(
                  props.propertyAttributeGroup.name,
                  "startTime",
                  getPropertyValue("allDay") ? "00:00" : null
                );
                props.onChange(
                  props.propertyAttributeGroup.name,
                  "endTime",
                  getPropertyValue("allDay") ? "00:00" : null
                );

                props.onChange(
                  props.propertyAttributeGroup.name,
                  "allDay",
                  !getPropertyValue("allDay")
                );
              }}
              fullHeight
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("event-repeatInterval")}
            description={tCms("event-repetitionDescription")}
            nonLocalized={
              getPropertyAttribute("repetitionInterval")?.nonLocalized
            }
          >
            <PbDropdown
              disabled={isNonLocalizedDisabled(
                getPropertyAttribute("repetitionInterval")
              )}
              dropdownList={
                (
                  getPropertyAttribute(
                    "repetitionInterval"
                  ) as CmsPropertyAttributeEnum
                )?.options?.map((element) => {
                  return { name: tCms(element.name), value: element.value };
                }) ?? []
              }
              onChange={(event: SelectChangeEvent) => {
                props.onChange(
                  props.propertyAttributeGroup.name,
                  "repetitionInterval",
                  event.target.value
                );
              }}
              defaultValue={
                getPropertyValue("repetitionInterval") ??
                (
                  getPropertyAttribute(
                    "repetitionInterval"
                  ) as CmsPropertyAttributeEnum
                )?.default
              }
              itemNameKey={"name"}
              itemValueKey={"value"}
            />
          </ModalInputWrapper>
          {!getPropertyValue("repetitionInterval") ||
            (getPropertyValue("repetitionInterval") !== "noRepetition" && (
              <ModalInputWrapper
                label={tCms("event-repeatUntil")}
                description={tCms("event-dateTimeUntilDescription")}
                nonLocalized={
                  getPropertyAttribute("repeatUntilDate")?.nonLocalized
                }
              >
                <PbDateTime
                  disabled={isNonLocalizedDisabled(
                    getPropertyAttribute("repeatUntilDate")
                  )}
                  value={parseDateAndTimeToDateTime(
                    getPropertyValue("repeatUntilDate"),
                    getPropertyValue("repeatUntilTime")
                  ).toISOString()}
                  hideTime={true}
                  onChange={(value, type) => {
                    onDateTimeInputChange(
                      "repeatUntilDate",
                      "repeatUntilTime",
                      value,
                      type
                    );
                  }}
                />
              </ModalInputWrapper>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
